import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["parts", "highlightedTagName", "nonHighlightedTagName", "separator", "className", "classNames"];
import { cx } from '@algolia/ui-components-shared'; // Basic types to allow this file to compile without a JSX implementation.
// This is a minimal subset of the actual types from the `JSX` namespace.

function createHighlightPartComponent(_ref) {
  var createElement = _ref.createElement;
  return function HighlightPart(_ref2) {
    var classNames = _ref2.classNames,
        children = _ref2.children,
        highlightedTagName = _ref2.highlightedTagName,
        isHighlighted = _ref2.isHighlighted,
        nonHighlightedTagName = _ref2.nonHighlightedTagName;
    var TagName = isHighlighted ? highlightedTagName : nonHighlightedTagName;
    return createElement(TagName, {
      className: isHighlighted ? classNames.highlighted : classNames.nonHighlighted
    }, children);
  };
}

export function createHighlightComponent(_ref3) {
  var createElement = _ref3.createElement,
      Fragment = _ref3.Fragment;
  var HighlightPart = createHighlightPartComponent({
    createElement: createElement,
    Fragment: Fragment
  });
  return function Highlight(_ref4) {
    var parts = _ref4.parts,
        _ref4$highlightedTagN = _ref4.highlightedTagName,
        highlightedTagName = _ref4$highlightedTagN === void 0 ? 'mark' : _ref4$highlightedTagN,
        _ref4$nonHighlightedT = _ref4.nonHighlightedTagName,
        nonHighlightedTagName = _ref4$nonHighlightedT === void 0 ? 'span' : _ref4$nonHighlightedT,
        _ref4$separator = _ref4.separator,
        separator = _ref4$separator === void 0 ? ', ' : _ref4$separator,
        className = _ref4.className,
        _ref4$classNames = _ref4.classNames,
        classNames = _ref4$classNames === void 0 ? {} : _ref4$classNames,
        props = _objectWithoutProperties(_ref4, _excluded);

    return createElement("span", _extends({}, props, {
      className: cx(classNames.root, className)
    }), parts.map(function (part, partIndex) {
      var isLastPart = partIndex === parts.length - 1;
      return createElement(Fragment, {
        key: partIndex
      }, part.map(function (subPart, subPartIndex) {
        return createElement(HighlightPart, {
          key: subPartIndex,
          classNames: classNames,
          highlightedTagName: highlightedTagName,
          nonHighlightedTagName: nonHighlightedTagName,
          isHighlighted: subPart.isHighlighted
        }, subPart.value);
      }), !isLastPart && createElement("span", {
        className: classNames.separator
      }, separator));
    }));
  };
}